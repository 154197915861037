document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.range-value').forEach(value => {
        const forRange = document.getElementById(value.getAttribute('data-target'));
        value.textContent = forRange.value;

        forRange.addEventListener('input', () => {
            value.textContent = forRange.value;
        });
    });

});
